<template>
  <div class="web-content">
    <Search :term='false' :isSearch="false" :isBtn="false" :termlist='termlist' :classlist="classlist" ref="search" @handleDeptChange="handleDeptChange" />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>平时成绩</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="examName" label="学业测试名称"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120"> </el-table-column>
      <el-table-column prop="studentNumber" label="参与学生数量" width="120">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >查看成绩</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {getUsualRecord} from '@/api/teacher/quality/Academic'
export default {
  data() {
    return {
      region: "",
      tableData: [],
      queryParams: {},
      typeId: '309',
      termlist: [],
      classlist: [],
    };
  },
  components: {Search},
  methods: {
    getFirst() {      
      this.$store.dispatch('GetTermList').then(res=>{
        this.termlist = res.data
      })
      this.$store.dispatch('GetClassList', this.typeId).then(res=>{
        this.classlist = res.data
        this.queryParams.termName = this.$store.getters.queryForm.termName
        this.queryParams.classId = this.$store.getters.queryForm.deptId
        this.$refs.search.init(this.queryParams.termName,this.queryParams.classId)
        this.getList()
      })
    },
    getList(){
      getUsualRecord(this.queryParams).then(res => {
        if(res.code == 0) {
          this.tableData = res.data
        }
      })
    },
    handleDeptChange(data){
      this.queryParams.classId = data
      this.getList()
    },
    handleDetail(row) {
      const params = {
        examId: row.id,
        examName: row.examName,
        examGrade: row.examGrade,
        classId: this.queryParams.classId
      }
      this.$router.push({name: 'usualDetails',params: params})
      sessionStorage['params'] = JSON.stringify(params)
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
.web-content {
  .search {
    margin-bottom: 10px;
  }
}
</style>
<style scoped>
.el-select >>> .el-input--suffix .el-input__inner {
  padding-left: 5px !important;
}
</style>