<template>
  <div class="web-content">
    <Search :isSearch="false" :isBtn="false" :termlist='termlist' :classlist="classlist" ref="search" @handleChange="handleChange" @handleDeptChange="handleDeptChange" />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>课堂表现评价</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right"> </el-col>
    </el-row>
    
    <el-form :model="forms" ref="forms" :rules="rules">
      <el-table
        :data="forms.tableData"
        border
        style="width: 100%"
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column type="index" label="序号" width="50"> </el-table-column>
        <el-table-column prop="userName" label="学生姓名"> </el-table-column>
        <el-table-column prop="userNum" label="学籍号" width="180">
        </el-table-column>
        <el-table-column prop="className" label="班级" width="120"> </el-table-column>
        <el-table-column label="教师评价" width="270">
          <template slot-scope="scope">
            <!-- <el-form-item :prop="'tableData.'+scope.$index+'.eval'" :rules="rules.eval"> -->
            <el-form-item>
              <el-radio-group v-model="scope.row.teacherEval" :disabled="forms.isign == false && isSubmit==true && isReSubmit == false">
                <el-radio label="10">A</el-radio>
                <el-radio label="8">B</el-radio>
                <el-radio label="6">C</el-radio>
                <el-radio label="4">D</el-radio>
              </el-radio-group>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="selfEval" label="学生自评"> </el-table-column>
        <el-table-column prop="otherEval" label="学生互评">
        </el-table-column>
        <el-table-column prop="evalZSScore" label="综素得分"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDetail(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    
    <div class="submit">
      <p class="tips" v-if="isTips">请对{{tips}}进行评价</p>
      <el-button type="primary" v-if="forms.isign && isSubmit" plain @click="submitEval">提交评价</el-button>
      <el-button type="primary" v-if="isReSubmit" plain @click="submitReEval">重新提交</el-button>
    </div>

    <!-- 详情 -->
    <el-dialog title="学生评价详情" width="750px" :visible.sync="open" center :destroy-on-close="true">
      <el-table 
        :data="gridData"
        border
        style="width: 100%"
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column type="index" label="序号" width="50"> </el-table-column>
        <el-table-column property="userName" label="姓名" width="150"></el-table-column>
        <el-table-column property="sex" label="性别" width="120"></el-table-column>
        <el-table-column label="表现情况">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.evalScore" disabled>
              <el-radio :label="10">A 优秀</el-radio>
              <el-radio :label="8">B 良好</el-radio>
              <el-radio :label="6">C 一般</el-radio>
              <el-radio :label="4">D 稍差</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {getEvaluate,saveEvaluate,updateEvaluate,getEvaluateDetail } from '@/api/teacher/quality/Academic'
export default {
  data() {
    return {
      forms: {
        tableData: [],
        isign: false
      },
      rules: {
        eval:[{required:true,message:'请对Ta进行评价',trigger:'change'}],
      },
      queryParams: {},
      typeId: '309',
      termlist: [],
      classlist: [],
      tips: '',
      isTips: false,
      isSubmit: true,
      isReSubmit: false,
      evalItemList: [],
      unEvalList: [],
      open: false,
      gridData: [],
    };
  },
  components: { Search },
  methods: {
    getFirst() {      
      this.$store.dispatch('GetTermList').then(res=>{
        this.termlist = res.data
      })
      this.$store.dispatch('GetClassList', this.typeId).then(res=>{
        this.classlist = res.data
        this.queryParams.termName = this.$store.getters.queryForm.termName
        this.queryParams.classId = this.$store.getters.queryForm.deptId
        this.$refs.search.init(this.queryParams.termName,this.queryParams.classId)
        this.getList()
      })
      this.isReSubmit = false
    },
    getList() {
      getEvaluate(this.queryParams).then(res => {
        if(res.data != null) {
          this.forms.tableData = res.data.recordList
          this.forms.isign = res.data.isign == 0 ? true : false
        }else {
          this.forms.tableData = []
        }
      })
    },
    handleChange(data) {
      this.queryParams.termName = data
      this.getList()
    },
    handleDeptChange(data) {
      this.queryParams.classId = data
      this.getList()
    },
    handleDetail(row) {
      getEvaluateDetail({userId: row.userId,termName: this.queryParams.termName,classId: this.queryParams.classId}).then(res => {
        if(res.data.length > 0) {
          this.gridData = res.data
          this.open = true
        }else {
          this.$message.warning('该学生还未参与评价！')
        }
      })
    },
    submitEval(){
      this.evalItemList = []
      this.unEvalList = []
      this.tips = ''
      this.forms.tableData.forEach(item => {
        if(item.teacherEval) {
          this.evalItemList.push({
            userId: item.userId,
            evalScore: item.teacherEval
          })
        }else {
          this.unEvalList.push(item.userName)
        }
      })
      if(this.unEvalList.length == 0) {
        this.isTips = false
        saveEvaluate({classId: this.queryParams.classId,termName: this.queryParams.termName,evalItemList: this.evalItemList}).then(res =>{
          this.$message.success('提交成功')
          // this.getList()
          this.isSubmit = false
          this.isReSubmit = true
        })
      }else {
        this.isTips = true
        this.unEvalList.forEach(item => {
          this.tips += item + '、'
        })
        this.tips = this.tips.substr(0,this.tips.length-1)
      }
    },
    submitReEval() {
      this.evalItemList = []
      this.unEvalList = []
      this.tips = ''
      this.forms.tableData.forEach(item => {
        if(item.teacherEval != null) {
          this.evalItemList.push({
            userId: item.userId,
            evalScore: item.teacherEval
          })
        }else {
          this.unEvalList.push(item.userName)
        }
      })
      if(this.unEvalList.length == 0) {
        this.isTips = false
        updateEvaluate({classId: this.queryParams.classId,termName: this.queryParams.termName,evalItemList: this.evalItemList}).then(res =>{
          this.$message.success('提交成功')
          this.getList()
          this.isSubmit = true
          this.isReSubmit = false
        })
      }else {
        this.isTips = true
        this.unEvalList.forEach(item => {
          this.tips += item + '、'
        })
        this.tips = this.tips.substr(0,this.tips.length-1)
      }
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
.web-content{
  .submit{
    width: 100%;
    margin: 10px;
    text-align: center;
    .tips{
      color: red;
    }
  }
}
</style>
<style scoped>
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-form-item >>> .el-form-item__content {
  line-height: 14px;
}
.el-image {
  width: 300px;
}
</style>