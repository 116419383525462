<template>
  <div class="container">
    <div class="tabs-nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="成绩汇总" name="0">
          <Summary ref="summary" />
        </el-tab-pane>
        <el-tab-pane label="平时成绩" name="1">
          <Usual ref="usual" />
        </el-tab-pane>
        <el-tab-pane label="期中成绩" name="2">
          <Midterm ref="midterm" />
        </el-tab-pane>
        <el-tab-pane label="期末成绩" name="3">
          <Final ref="final" />
        </el-tab-pane>
        <el-tab-pane label="学科比赛" name="4">
          <Competition ref="competition" />
        </el-tab-pane>
        <el-tab-pane label="课堂表现评价" name="5">
          <Evaluation ref="evaluation" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Summary from './component/resultsSummary'
import Usual from './component/usualResults'
import Midterm from './component/midTermResults'
import Final from './component/finalResults'
import Competition from './component/competition'
import Evaluation from './component/evaluation'
export default {
  data() {
    return {
      activeName: "0",
    };
  },
  components: {
    Summary,
    Usual,
    Midterm,
    Final,
    Competition,
    Evaluation
  },
  created() {
    this.activeName = this.$route.params.activeName
    this.handleClick()
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
      this.$nextTick(()=>{
        switch(this.activeName) {
          case '0': this.$refs.summary.getFirst();break;
          case '1': this.$refs.usual.getFirst();break;
          case '2': this.$refs.midterm.getFirst();break;
          case '3': this.$refs.final.getFirst();break;
          case '4': this.$refs.competition.getFirst();break;
          case '5': this.$refs.evaluation.getFirst();break;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tabs-nav {
    width: 100%;
    padding: 15px 16px 30px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }
}
</style>
<style scoped>
.el-tabs--top >>> .el-tabs__item.is-top:nth-child(2){
  padding-left: 20px;
}
</style>