<template>
  <div class="web-content">
    <Search
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>期中成绩</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <!-- <el-button type="primary" plain @click="handleImport">导入</el-button> -->
        <el-button
          style="display: inline-block; margin-left: 10px"
          type="primary"
          plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="期中成绩.xls"
          >
            导出
          </download-excel></el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="userName" label="学生姓名">
      </el-table-column>
      <el-table-column prop="loginName" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column v-for="(item,index) in titleList" :key="index" :prop="item.code" :label="item.name"> </el-table-column>
      <!-- <el-table-column prop="userExamIndexScore" label="综素成绩">
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  getMidResult,
  updateResult,
  importMidResult,
} from "@/api/teacher/quality/Academic";
export default {
  data() {
    return {
      tableData: [],
      titleList: [],
      title: "",
      open: false,
      openImport: false,
      isUp: true,
      form: {},
      total: 0,
      queryParams: {
        examType: '2',
        pageSize: 100,
      },
      typeId: "309",
      termlist: [],
      classlist: [],
      fields: {
        学生姓名: "userName",
        学籍号: "loginName",
        班级: "className",
      },
    };
  },
  components: {
    Search,
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = this.$store.getters.queryForm.termName;
        this.queryParams.classId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      getMidResult(this.queryParams).then((res) => {
        if (res.data) {
          this.tableData = res.data.content
          this.titleList = res.data.title
          this.titleList.forEach(item => {
            Object.assign(this.fields, {[item.name]: item.code})
          })
          Object.assign(this.fields, { 综素成绩: "userExamIndexScore"})
        }
      });
    },
    handleQuery(data) {
      this.queryParams.termName = data.term;
      this.queryParams.classId = data.deptId;
      this.queryParams.userNameOrLoginName = data.userName;
      this.getList();
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
</style>
<style scoped>
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 100%;
}
</style>